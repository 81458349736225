.float-right {
  float: right;
}

.position-right {
  text-align: right;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

/*--------------------------------------------------------------
# Paddings
--------------------------------------------------------------*/

.p-1 {
  padding: ($spacer * .5);
}

.p-2 {
  padding: $spacer;
}

.p-3 {
  padding: ($spacer * 1.5);
}

.p-4 {
  padding: ($spacer * 2);
}

.p-5 {
  padding: ($spacer * 3);
}

.p-6 {
  padding: ($spacer * 6);
}

/*--------------------------------------------------------------
# Margins
--------------------------------------------------------------*/

.m-1 {
  margin: ($spacer * .5) !important;
}

.m-2 {
  margin: $spacer !important;
}

.m-3 {
  margin: ($spacer * 1.5) !important;
}

.m-4 {
  margin: ($spacer * 2) !important;
}

.m-5 {
  margin: ($spacer * 3) !important;
}

.m-6 {
  margin: ($spacer * 6) !important;
}

// Margin Top

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: ($spacer * .5) !important;
}

.mt-2 {
  margin-top: $spacer !important;
}

.mt-3 {
  margin-top: ($spacer * 1.5) !important;
}

.mt-4 {
  margin-top: ($spacer * 2) !important;
}

.mt-5 {
  margin-top: ($spacer * 3) !important;
}

.mt-6 {
  margin-top: ($spacer * 6) !important;
}

// Margin Bottom

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: ($spacer * .5) !important;
}

.mb-2 {
  margin-bottom: $spacer !important;
}

.mb-3 {
  margin-bottom: ($spacer * 1.5) !important;
}

.mb-4 {
  margin-bottom: ($spacer * 2) !important;
}

.mb-5 {
  margin-bottom: ($spacer * 3) !important;
}

.mb-6 {
  margin-bottom: ($spacer * 6) !important;
}