/*--------------------------------------------------------------
# Color variables
# Usage: color(bg, light)
--------------------------------------------------------------*/
$color: (
  bg: (
    brand     : #FD6D00,
    white     : #fff,
    light     : #f5f9ff,
    dark      : #888,
    light-blue: #ebf0ff,
    blue      : #23b7e5,
    blue_02   : #2f80e7,
    green     : #41b883,
    red       : #F05050,
    yellow    : #F5A623,
    gray      : #eaeaea,
    light-gray: #eeeeee,
    input-gray: #b7bac9,
    bronze    : #A28E7C,
    silver    : #B4B4B4,
    gold      : #E9AD54
  ),
  typo: (
    dark   : #333333,
    regular: #656565,
    gray   : #888888,
    light  : #b7bac9,
    red    : #F05050,
    green  : #41b883,
    yellow : #F5A623,
    link   : #5D9CEC
  )
);

/*--------------------------------------------------------------
# Shadow variables
--------------------------------------------------------------*/
$shadow-sm: 0 0 1px rgba(0, 0, 0, 0.2);
$shadow-md: 0 0 4px rgba(0, 0, 0, 0.2);
$shadow-lg: 0 0 8px rgba(0, 0, 0, 0.1);
$shadow-xxl: 0 0 128px rgba(0,0,0,0.2);
$shadow-ext: 0 2px 4px 0 rgba(0,0,0,0.1);

/*--------------------------------------------------------------
# Border radius variables
--------------------------------------------------------------*/
$radius-sm: 2px;
$radius-md: 4px;
$radius   : 8px;
$radius-lg: 10px;
$radius-xl: 16px;
$radius-r : 50%;

/*--------------------------------------------------------------
# Common borders
--------------------------------------------------------------*/
$border-light: 1px solid #eeeeee;
$border-gray: 1px solid darken(#eeeeee, 10);
$border-error: 1px solid #F05050;
$border-blue: 1px solid #5D9CEC;

/*--------------------------------------------------------------
# Default spacer
--------------------------------------------------------------*/
$spacer: 10px;

/*--------------------------------------------------------------
# Media query breakpoints
--------------------------------------------------------------*/
$breakpoints: (
  'small': (max-width: 480px),
  'medium': (max-width: 800px),
  'large': (max-width: 1200px),
);
