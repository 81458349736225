.btn {
  display: inline-flex;
  padding: 8px 30px;
  font-size: 14px;
  line-height: 16px;
  outline: none !important;
  border-radius: $radius;
  cursor: pointer;

  &+.right {
    float: right;

    &+.btn {
      margin-right: 15px;
    }
  }

  &+.btn {
    margin-left: 15px;
  }

  &+.left {
    float: left;
  }
  &+.center {
    display: block;
    margin: 0 auto;
  }

  &[disabled]:hover {
    pointer-events: none;
  }
}

.btn-primary {
  background-color: color(bg, blue_02);
  transition: all .2s ease-in-out;

  &:hover {
    background-color: darken(color(bg, blue_02), 6);
  }
}

.btn-ghost {
  background-color: transparent;
  border: $border-gray;
  color: color(typo, gray);
  transition: all .2s ease-in-out;

  &:hover {
    background-color: darken(color(bg, light), 2);
  }
}

.btn-warning {
  border: 1px solid;
  background-color: color(bg, red);
  transition: all .2s ease-in-out;

  &:hover {
    background-color: darken(color(bg, red), 6);
  }
}

.btn-success {
  border: 1px solid color(bg, green);
  background-color: color(bg, green);
  color: white;
  transition: all .2s ease-in-out;

  &:hover {
    background-color: darken(color(bg, green), 6);
  }
}

.btn-icon {
  padding: 0;
  border-radius: $radius-xl;
  background: color(bg, blue_02);
  font-size: 14px;
  font-weight: 500;
  color: white;
  letter-spacing: 0;
  line-height: 16px;
  border: none;
  transition: .3s;

  i {
    width: 32px;
    font-size: 12px;
    line-height: 32px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: $radius-r;
  }

  &:hover {
    background-color: darken(color(bg, blue_02), 6);

    .fa-sync-alt {
        animation: rotate-360 2s linear infinite;
    }
  }

  .fa-sync-alt {
      animation: rotate-90 .3s linear;
  }

  span {
    padding: 0 20px 0 10px;
  }

  &.success {
    background-color: color(bg, green);

    &:hover {
      background-color: darken(color(bg, green), 6);
    }
  }

  &.warning {
    background-color: color(bg, red);

    &:hover {
      background-color: darken(color(bg, red), 6);
    }
  }
}

.fa-spinner {
  animation: rotate-360 2s linear infinite;
}

// Button sizes

.btn-sm {
  padding: 5px 15px;
  font-size: 12px;
  line-height: 1.5;
}

.btn-lg {
  padding: 10px 30px;
  font-size: 18px;
  line-height: 1.33;
}