@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

html {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

h2 {
  font-weight: 600;
  font-size: 24px;
  color: #444447;
  letter-spacing: 0;
  text-align: center;
  line-height: 38px;
}

h3 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
}